.galarry-block_1,
.galarry-block_4,
.galarry-block_2,
.galarry-block_3,
.galarry-block_5 {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.block {
  padding: 25px;
  background-color: aliceblue;
  text-align: center;
  border-radius: 20px;
  max-width: 40%;
  margin: 0 auto;
  position: relative;
  top: 10px;
  left: 0;
}
.special {
  padding: 30px;
  background-color: aliceblue;
  font-size: 18px;
  border-radius: 10px;
  min-height: 130px;
  margin: 35px 25px;
}
@media (max-width: 650px) {
  .galarry-block_1,
  .galarry-block_4,
  .galarry-block_2,
  .galarry-block_3,
  .galarry-block_5 {
    padding: 15px;
    background-color: white;
    border-radius: 10px;
  }
}
@media (max-width: 500px) {
  .galarry-block_1,
  .galarry-block_4,
  .galarry-block_2,
  .galarry-block_3,
  .galarry-block_5 {
    padding: 5px;
    background-color: white;
    border-radius: 10px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: #ffffff;
  }
}
