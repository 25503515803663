.logo {
  display: block;
  width: 120px;
  height: 120px;
}
.logo__img {
  transform: scale(1.2);
  border-radius: 25px 0 25px 0;
  border: 1px solid rgba(8, 209, 8, 0.5);
}
@media (max-width: 480px) {
  .logo__img {
    transform: scale(1.1);
  }
}
/* @media (max-width: 430px) {
  .logo__img {
    transform: scale(0.8);
  }
} */
