/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/poppins-v21-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/poppins-v21-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/poppins-v21-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

body {
  font-family: Lato, Roboto, Arial, sans-serif;
}

.lato-thin {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-style: italic;
}
