.main__first-services {
  padding-top: 45px;
}
.main-services .main-services-upper span {
  text-transform: uppercase;
  font-weight: 600;
}
.main-services {
  position: relative;
  margin: 10px auto 45px auto;
  background-color: azure;
  padding: 60px 50px;
  opacity: 0.9;
  border-radius: 20px;
  max-width: 1000px;
  text-align: center;
  font-family: 'Open Sans';
}
.main-services .h3 {
  font-weight: 600;
  font-size: 55px;
  color: #333333;
  padding-top: 30px;
}

.main-services .h1 {
  max-width: 932px;
  font-weight: 600;
  font-size: 35px;
  color: #333333;
  margin: 35px auto 35px;
  line-height: 1.3;
  text-transform: uppercase;
}
.main-services .h2 {
  max-width: 932px;
  margin: 20px auto 30px;
  font-weight: 600;
  text-transform: uppercase;
}
.main__services-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  transition: all 0.3s;
}
.main-services-price {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  padding: 10px 0px;
  width: 100%;
  max-width: 200px;
  color: rgb(3, 95, 199);
  outline: none;
  border: none;
  border: 1px solid rgba(128, 128, 128, 0.5);
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  color: rgb(255, 255, 255);
  background-color: rgb(22, 130, 254);
}
.main__services-backdrop:hover {
  background-color: rgba(144, 141, 141, 0.1);
  cursor: pointer;
}
.main-services h1 {
  font-size: 28px;
  font-weight: 600;
  color: black;
}
.main__services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}
.main__services-item {
  position: relative;
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  transition: all 0.5s;
}
.main__services-item:hover {
  border: 1px solid rgba(0, 0, 255, 0.664);
}
.main__services-item h2 {
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(1, 82, 175);
}
.main__services-item-span {
  display: block;
  text-transform: none;
  margin-top: 10px;
}

.main__link-details {
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  background-color: rgb(7, 80, 140);
  width: 200px;
  height: auto;
  color: white;
}
.main__link-details:hover {
  background-color: rgb(3, 112, 137);
}
.main__link-details a {
  font-size: 20px;
  display: inline-block;
  padding: 15px;
}
.main-camera-img {
  scale: 0.8;
  position: relative;
  top: 10px;
  right: 5px;
}
.main-camera-img-rigth {
  scale: 0.8;
  position: relative;
  top: 10px;
  right: -5px;
}
.main-drone-img {
  scale: 0.8;
  position: relative;
  top: 15px;
  left: -10px;
}
.main__services-img2 {
  width: 100%;
  height: 50%;
}
.btn-service {
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 1102px) {
  .main-services .h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 850px) {
  .main__services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 20px;
  }
  .titlerow {
    display: block;
    width: 100%;
    font-size: 4.5vw;
  }
  .en-titlerow {
    display: block;
    width: 100%;
    font-size: 4.2vw;
  }
  .main-services {
    padding: 70px 2px;
  }
  .main-services .h3 {
    font-size: 6.6vw;
    padding-top: 30px;
    letter-spacing: 1px;
  }
  .main-services .h1 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .main-services .h2 {
    font-size: 3vw;
    margin: 20px auto 20px;
  }
}

@media (max-width: 600px) {
  .main-services {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .main-services .h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .main-camera-img {
    scale: 0.7;
    position: relative;
    top: 15px;
    right: 0px;
  }
  .main-camera-img-rigth {
    scale: 0.5;
    position: relative;
    top: 15px;
    right: 0px;
  }

  .main__services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 20px;
  }
  .main__services-item h2 {
    font-size: 20px;
  }
}
@media (max-width: 434px) {
  .main-camera-img {
    scale: 0.5;
    position: relative;
    top: 20px;
    right: 0px;
  }
  .camera-closed {
    display: none;
  }
  .main-drone-img {
    scale: 0.6;
    position: relative;
    top: 20px;
    left: 5px;
  }
  .main-services .main-services-upper {
    font-size: 19px;
  }
  .en-titlerow {
    display: block;
    width: 100%;
    font-size: 4vw;
  }
}
@media screen and (max-width: 375px) {
  .titlerow {
    display: block;
    width: 100%;
    font-size: 4.3vw;
  }
  .main-services {
    padding: 30px 2px;
  }
  .main-services .h3 {
    font-size: 6.2vw;
    padding-top: 30px;
    letter-spacing: 1px;
  }
  .main-services .h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .main-services .h2 {
    font-size: 3vw;
    margin: 20px auto 20px;
  }
}

@media (max-width: 360px) {
  .main-services p {
    font-size: 15px;
  }

  .main-services .main-services-upper {
    font-size: 17px;
  }
}
