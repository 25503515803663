/* .main__important {
  background-color: aliceblue;
  padding: 30px;
} */
.main-important-warning {
  background-color: aliceblue;
  margin-top: 20px;
}
.main-important-warning p {
  text-align: center;
  opacity: 0.9;
  border-radius: 20px;
  font-size: 20px;
  padding: 0px 35px 35px 35px;
}
.main-important-warning img {
  display: block;
  margin: 0 auto;
  padding: 20px;
}
.main-important-blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.main-important-h2 {
  text-align: center;
  padding: 35px;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
}
.main-important-h2 span {
  padding: 35px;
  background-color: aliceblue;
  border-radius: 45%;
  opacity: 0.9;
}
.main-important-blocks div {
  background-color: aliceblue;
  padding: 25px;
  margin-top: 20px;
}
.expert {
  display: block;
  margin: 0 auto;
}
.main-important-block h3 {
  text-align: center;
  padding: 15px;
}
.main-important-block p {
  font-size: 16px;
  text-align: center;
}
.expert2 {
  border-radius: 50%;
  width: 120px;
}
.quality {
  border-radius: 50%;
  width: 100px;
}
.profile {
  width: 100px;
}
@media (max-width: 900px) {
  .main-important-blocks {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
@media (max-width: 550px) {
  .main-important-warning p {
    padding: 0 15px 15px 15px;
    font-size: 18px;
  }
  .main-important-h2 span {
    padding: 25px;
    display: inline-block;
    font-size: 18px;
  }
}
@media (max-width: 360px) {
  .main-important-warning p {
    padding: 0 5px 15px 5px;
    font-size: 16px;
  }
  .main-important-h2 span {
    display: inline-block;
    font-size: 16px;
  }
}
