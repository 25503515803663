.body_wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.vectorup {
  transform: rotate(180deg);
  transition: 0.3s;
}
.none {
  transition: 0.3s;
}
.select {
  position: absolute;
  top: -30px;
  left: 0;
  display: flex;
  padding: 7px;
  width: 50px;
  min-height: 35px;
  background-color: rgb(5, 5, 142);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
}
.ee {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 50px;
  height: 40px;
  background-color: rgb(5, 5, 142);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  z-index: 5;
  border-top: 1px solid#fff;
}
.ee a {
  display: inline-block;
  color: #fff;
  width: 100%;
}
.hidden {
  display: none;
}
@media (max-width: 850px) {
  .select {
    position: absolute;
    top: -35px;
    left: 0;
    display: flex;
    padding: 7px;
    width: 50px;
    min-height: 35px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
  }
  .ee {
    position: absolute;
    top: 3px;
    left: 0px;
    width: 50px;
    height: 40px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    z-index: 5;
    border-top: 1px solid#fff;
  }
}
@media (max-width: 400px) {
  .select {
    position: absolute;
    top: -35px;
    left: 0;
    display: flex;
    padding: 7px;
    width: 50px;
    min-height: 35px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
  }
  .ee {
    position: absolute;
    top: 2px;
    left: 0px;
    width: 50px;
    height: 40px;
    background-color: rgb(5, 5, 142);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    z-index: 5;
    border-top: 1px solid#fff;
  }
}
