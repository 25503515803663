.uslugi {
  background-color: #fff;
}
.wrapper__uslugi {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-bottom: 50px;
  margin-top: 20px;
}
.wrapper__img-text {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 0 auto;
  width: 100%;
}
.wrapper__img-text img {
  border-radius: 10px;
  width: 100%;
}
.swiper-pagination {
  color: #ffffff;
}
.uslugi__text .nomargin-top-p-moikavikon {
  margin-top: 10px;
  display: block;
}
.uslugi-list ul {
  width: 300px;
  list-style: none;
}
.uslugi-list span {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  padding: 10px 0;
}
.uslugi-list ul li {
  width: 100%;
  font-size: 18px;
  margin: 20px auto 10px auto;
  display: inline-block;
}
.uslugi-list ul li:hover {
  color: #03a9f4;
}
.uslugi__line-under {
  width: 60%;
  height: 2px;
  background-color: rgb(22, 114, 218);
  opacity: 0.2;
  margin-left: auto;
}
.uslugi__text {
  background: #f4fbfd;
  background: #f9fdfd;
  padding: 15px 25px;
  font-size: 18px;
}
.uslugi__text .dywany-h3 span {
  display: inline-block;
  margin-top: 10px;
}
.uslugi__text .usluga-car-ol li {
  margin: 0;
  margin-top: 10px;
  margin-left: 20px;
}
.uslugi__text h2 {
  text-transform: uppercase;
  text-align: center;
  margin: 45px 0;
}
.uslugi__text h3 {
  margin: 30px 0;
  font-size: 18px;
}
.uslugi__text ul,
.uslugi__text ol {
  margin-left: 30px;
}
.uslugi__text ul {
  margin-left: 15px;
}
.uslugi__text .p-span {
  font-style: italic;
}
.uslugi__text .p-span span {
  display: block;
  margin-top: 10px;
  font-style: normal;
}
.uslugi__text li {
  margin-top: 30px;
  position: relative;
  left: -13px;
}
.uslugi__text .dywany-ol-li {
  margin-top: 30px;
  position: relative;
  left: 10px;
}
.uslugi__text .dywany-end-p1 {
  margin-bottom: 5px;
}
.uslugi__text .dywany-end-p2 {
  margin-top: 5px;
}
.uslugi__text .dywany-ul-li {
  margin-top: 30px;
  position: relative;
  left: 20px;
}
.uslugi__text .ol-p {
  margin: 0;
  margin-top: 10px;
  position: relative;
  left: -30px;
  width: 104%;
}
.uslugi__text-title {
  display: block;
  text-transform: none;
  margin-top: 10px;
}
.uslugi__text p {
  font-size: 18px;
  margin-top: 35px;
  margin-bottom: 35px;
  text-align: justify;
}

.uslugi-notes {
  margin-top: 20px;
  font-weight: 600;
}
.uslugi-notes span {
  display: inline-block;
  padding-top: 10px;
  font-weight: 400;
}
.uslugi-notes-phone {
  color: #300bff;
  font-weight: 600;
}
.uslugi-notes-phone:hover {
  color: #0a8fcd;
  text-decoration: underline;
}
.uslugi__text .nostyle-ol-ul {
  list-style-type: none;
}
.uslugi__text .noitalic-ol-ul {
  margin-left: 25px;
}
.uslugi__text .noitalic-ol-ul li {
  font-style: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1000px) {
  .uslugi-list ul {
    width: 250px;
    list-style: none;
  }
}
@media (max-width: 800px) {
  .wrapper__uslugi {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-bottom: 50px;
  }
  .uslugi__text .ol-p {
    width: 105%;
  }
}
@media (max-width: 500px) {
  .uslugi__text p,
  .uslugi__text ul,
  .uslugi__text h3,
  .uslugi__text ol,
  .uslugi__text ul {
    font-size: 15px;
  }
  .uslugi__text h2 {
    font-size: 18px;
  }
  .uslugi__text .ol-p {
    width: 108%;
  }
}
@media (max-width: 400px) {
  .uslugi-notes-phone {
    display: block;
    margin-top: 10px;
  }
  .uslugi__text .ol-p {
    width: 112%;
  }
}
@media (max-width: 350px) {
  .uslugi__text .ol-p {
    width: 115%;
  }
}
@media (max-width: 330px) {
  .uslugi-list ul li {
    font-size: 14px;
  }
}
