.header__phone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-phone-item {
  padding-left: 0px;
  padding-right: 10px;
  margin: 0;
  width: 100%;
}
.phone-contact {
  margin-top: 10px;
}
.header-phone-item:hover {
  color: rgb(91, 118, 163);
}
.header__phone-img {
  scale: 0.65;
}
.header__phone-only {
  padding: 15px 0 15px 0;
}
.header__phone-only:hover {
  color: rgb(91, 118, 163);
}
@media (max-width: 650px) {
  .header__phone-link {
    width: 50px;
  }
}

@media (max-width: 530px) {
  .header__phone-img {
    scale: 0.65;
  }
  .header-phone-item {
    padding: 5px;
    padding-left: 5px;
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .FiPhoneCall {
    scale: 1.2;
    padding-right: 5px;
  }
  .header-phone-item {
    padding: 5px;
    padding-left: 0px;
  }
}
@media (max-width: 310px) {
  .header__phone-only {
    padding: 15px;
  }
  .FiPhoneCall {
    scale: 1;
    padding-right: 5px;
  }
  .header-phone-item {
    padding: 5px;
    padding-left: 0px;
    font-size: 14px;
  }
}
