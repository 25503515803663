.footer {
  background-color: rgb(40, 36, 31);
  height: auto;
  padding: 35px 0 5px 0;
}
.footer__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-menu {
  display: block;
}
.footer__menu__list {
  display: block;
  list-style-type: none;
}
.footer__menu__link {
  display: inherit;
  color: #ffffff;
  padding: 15px 0px;
  font-size: 20px;
}
.footer__menu__link:hover {
  color: rgb(91, 118, 163);
}
.footer-phone {
  color: white;
}
.footer__down {
  color: rgb(177, 173, 173);
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 330px) {
  .footer__down {
    font-size: 12px;
  }
}
