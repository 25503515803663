.price {
  background-image: url(../../../img/fonSmall.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  padding-bottom: 35px;
}
.price-padding {
  margin: 0px auto;
  max-width: 1200px;
  border: 3px solid white;
  background-color: white;
  padding: 35px;
}

.price-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.price-title h1 {
  text-align: center;
  color: rgb(21, 4, 62);
  text-transform: uppercase;
}
.price-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.price-padding__items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.price-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 10px;
  text-align: center;
  flex-basis: calc((100% - 30px) / 4);
  height: 100%;
  max-height: 200px;
  background-color: #fafafa;
  font-weight: 600;
}
.price-item img {
  width: 100%;
  max-width: 60px;
}
.price-name {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
}
.price-name.size {
  font-size: 16px;
}
.price-cost {
  background: #ffc73b;
  padding: 12px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
}
.price-cost.wider {
  padding: 12px 40px;
}
.outsidewidthtable {
  padding: 20px 0;
  max-width: 1000px;
  margin: 0 auto;
}
.outsidewidthtable .lastquestion {
  max-width: 90%;
  padding-bottom: 20px;
  margin-left: 5%;
}

.outsidewidthtable p span {
  display: block;
  padding: 10px;
  background-color: azure;
  border-radius: 10px;
  color: rgb(192, 1, 83);
  border-bottom: 2px dotted rgb(252, 93, 14);
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.outsidewidthtable .attantion {
  color: rgb(205, 5, 5);
  border-bottom: none;
}

@media screen and (max-width: 800px) {
  .price-padding {
    padding: 15px;
  }
  .price-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 10px;
    text-align: center;
    flex-basis: calc((100% - 20px) / 3);
    height: 100%;
    max-height: 200px;
    background-color: #fafafa;
    font-weight: 600;
  }
}
@media screen and (max-width: 650px) {
  .price-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 10px;
    text-align: center;
    flex-basis: calc((100% - 10px) / 2);
    height: 100%;
    max-height: 200px;
    background-color: #fafafa;
    font-weight: 600;
  }
}

@media screen and (max-width: 590px) {
  .price {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .price-padding {
    padding: 10px;
  }
  .price-item img {
    width: 100%;
    max-width: 50px;
  }
  .price-name {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .price-name.size {
    font-size: 14px;
  }
  .price-cost {
    background: #ffc73b;
    padding: 12px;
    font-weight: 700;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 13px;
  }
}
@media screen and (max-width: 345px) {
  .price-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 10px;
    text-align: center;
    flex-basis: calc((100%) / 1);
    height: 100%;
    max-height: 200px;
    background-color: #fafafa;
    font-weight: 600;
  }
}
@media screen and (max-width: 400px) {
  .outsidewidthtable p {
    width: 200px;
  }
  .outsidewidthtable .lastquestion {
    width: 85%;
  }
}
@media screen and (max-width: 370px) {
  .whatsapp img {
    padding-right: 0;
    padding-left: 130px;
    padding-top: 10px;
  }
  .whatsapp {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone span {
    width: 250px;
    font-size: 18px;
    padding-left: 70px;
    padding-top: 15px;
  }
  .numerPhone p {
    font-size: 20px;
  }
  .wrapcontacts .link {
    font-size: 20px;
  }
}
@media screen and (max-width: 330px) {
  .whatsapp img {
    padding-right: 0;
    padding-left: 110px;
    padding-top: 10px;
  }
  .whatsapp {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone {
    width: 340px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone span {
    font-size: 18px;
    padding-left: 40px;
    padding-top: 15px;
  }
  .allphoto {
    width: 500px;
    cursor: default;
    transition: 0.3s;
  }
  .onerow {
    display: block;
    width: 250px;
    margin: 20px auto;
  }
  .allphoto .onerow img {
    width: 250px;
    margin: 20px auto;
  }
  .tworow {
    width: 250px;
    display: block;
    margin: 20px auto;
  }
  .allphoto .tworow img {
    width: 250px;
    height: 350px;
    margin: 20px auto;
  }
  .threerow {
    display: none;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 270px;
    margin: 0 auto;
    font-size: 12px;
  }
  .containerOnas img {
    display: block;
    width: 270px;
    margin: 20px auto 20px;
  }
  .mainContacts p {
    width: 250px;
    font-size: 14px;
  }
  .contacts {
    padding-left: 0;
    width: 250px;
  }
  .wrapcontacts .link {
    font-size: 13px;
  }
  .phone {
    width: 250px;
  }
  .vibertelegram {
    margin-left: 80px;
  }
  .mail {
    width: 250px;
  }
  .galaryh2 {
    text-align: center;
    padding-bottom: 0px;
    color: black;
  }
  .planpracy img {
    display: block;
    margin: 10px auto 10px;
    width: 250px;
  }
}
